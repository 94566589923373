import { path, pathOr, mergeDeepRight, unnest } from 'ramda';
import { ActivityHistoryItem } from 'admin/helpers/processes';
import { longSignatureMeaning } from 'helpers/signature';
import { toLocal, toLocalDate, toLocalSentence } from 'helpers/time';

export interface HydratedActivityHistory {
  id: string;
  user: any;
  avatar?: any;
  title: string;
  timestamp: string;
  body: string[];
  shortBody: string[];
  isAlert: boolean;
  button?: {
    buttonText: string;
    buttonEvent: string;
  };
}

// tslint:disable:max-line-length

const getTaskStatusLabel = (taskStatus: string) => window.App.taskStatusLabels.find((taskStatusLabel) => taskStatusLabel.value === taskStatus).text

const buildDefaultHistory = (a: ActivityHistoryItem, h: Partial<HydratedActivityHistory>): HydratedActivityHistory => {
  const timestamp = toLocal(a.metadata.timestamp);
  return mergeDeepRight({
    id: a.id,
    type: a.type,
    user: a.data ? a.data.user : null,
    avatar: a.avatar,
    timestamp,
    title: '',
    body: [],
    isAlert: false
  }, h) as HydratedActivityHistory;
};

export const toFullName = (event: ActivityHistoryItem) => {
  const firstName: string = pathOr('', ['data', 'user', 'first_name'], event);
  const lastName: string = pathOr('', ['data', 'user', 'last_name'], event);
  return `${firstName} ${lastName}`;
};

const buildTaskStatusUpdated = (a: ActivityHistoryItem, p: Process, status: string, isAlert = false) => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  return buildDefaultHistory(a, {
    title: isAlert ? getTaskStatusLabel('critical_issue') : 'Task Status Updated',
    isAlert,
    body: [
      `${toFullName(a)} updated the task "${task.title}" of step "${step.title}" to a status of ${status}.`
    ],
    shortBody: [
      `${toFullName(a)} updated this task to status: ${status}.`
    ]
  });
};

const buildTaskRuleApplied = (a: ActivityHistoryItem, p: Process, status: string, isAlert = false) => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  const tasks = unnest(p.steps.map((s) => s.tasks));
  const sourceStep = p.steps.find((s) => s.id === a.data.source_step_id);
  const sourceTask = tasks.find((t) => t.id === a.data.source_task_id);
  const body = a.data.rule_type === 'required'
    ? [`Task "${task.title}" of step "${step.title}" was made active by a task rule associated with task "${sourceTask.title}" of step "${sourceStep.title}".`]
    : [`Task "${task.title}" of step "${step.title}" was made inactive by a task rule associated with task "${sourceTask.title}" of step "${sourceStep.title}".`];

  return buildDefaultHistory(a, {
    title: 'Task Rule Applied',
    isAlert,
    body
  });
};

const buildSummaryTaskRuleApplied = (a: ActivityHistoryItem, p: Process, status: string, isAlert = false) => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  const body = a.data.rule_type === 'required'
    ? [`Task "${task.title}" of step "${step.title}" was made active by a task rule associated with the summary form".`]
    : [`Task "${task.title}" of step "${step.title}" was made inactive by a task rule associated with the summary form.`];


  return buildDefaultHistory(a, {
    title: 'Task Rule Applied',
    isAlert,
    body
  });
};

const hydrateCommentAdded = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Comment Added',
    body: [
      `${toFullName(a)} commented on the task "${a.data.task_title}".`,
      `${a.data.text}`
    ]
  });
};

const hydrateDoubleBlind = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Double-Blind Mismatch',
    body: [
      `The task "${a.data.task_title}" recorded a mismatch of double-blind fields. Submitted by ${toFullName(a)}.`
    ],
    isAlert: true
  });
};

const hydrateProcessClosed = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Process Closed',
    body: [
      `${toFullName(a)} closed this process.`
    ]
  });
};

const hydrateProcessReopened = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Process Re-Opened',
    body: [
      `${toFullName(a)} re-opened this process.`,
    ]
  });
};

const hydrateProcessStarted = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Process Started',
    body: [
      `${toFullName(a)} started this process.`,
      `Process Id: ${a.data.id}.`
    ]
  });
};

const hydrateStepAdded = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Step Added',
    body: [
      `${toFullName(a)} added the step "${a.data.procedure_step.title}".`
    ]
  });
};

const hydrateProcessShared = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Process Shared',
    body: [
      `${toFullName(a)} emailed a PDF summary of this process to one or more parties.`
    ]
  });
};

const hydrateSummaryUpdated = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Process Summary Updated',
    body: [
      `${toFullName(a)} updated the process summary.`,
    ],
    button: {
      buttonText: 'Show Details',
      buttonEvent: 'show-process-summary'
    }
  });
};

const hydrateTaskStatusOverride = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  return buildDefaultHistory(a, {
    title: 'Task Status Override ',
    body: [
      `${toFullName(a)} overrode the task "${task.title}" of step "${step.title}" to a status of Done.`
    ],
    shortBody: [
      `${toFullName(a)} overrode this task to status: Done.`
    ],
    isAlert: true
  });
};

const hydrateTaskCompleted = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory =>
  buildTaskStatusUpdated(a, p, getTaskStatusLabel('done'));

const hydrateTaskStarted = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory =>
  buildTaskStatusUpdated(a, p, getTaskStatusLabel('in_progress'));

const hydrateTaskInvalidated = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory =>
  buildTaskStatusUpdated(a, p, getTaskStatusLabel('not_required'));

const hydrateTaskStopped = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory =>
  buildTaskStatusUpdated(a, p, getTaskStatusLabel('critical_issue'), true);

const hydrateTaskHalted = (a: ActivityHistoryItem, p: Process) => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  return buildDefaultHistory(a, {
    title: 'Task Status Updated',
    isAlert: true,
    body: [
      `The task "${task.title}" of step "${step.title}" was updated to a status of Halted due to inactivity.`
    ],
    shortBody: [
      'Updated this task to status: Halted.'
    ]
  });
};

const hydrateTaskRuleApplied = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory =>
  buildTaskRuleApplied(a, p, 'Not Required');

const hydrateNumberOutOfRange = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  return buildDefaultHistory(a, {
    title: 'Number outside of range',
    body: [
      `${toFullName(a)} updated the task "${task.title}" of step "${step.title}" with an out of range value.`
    ],
    isAlert: true
  });
};

const hydrateSummaryTaskRuleApplied = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory =>
  buildSummaryTaskRuleApplied(a, p, 'Not Required');

const hydrateFormUpdated = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  return buildDefaultHistory(a, {
    title: 'Form Updated',
    body: [
      `${toFullName(a)} updated the form "${a.data.form_name}" in task "${task.title}" of step "${step.title}".`
    ],
    button: {
      buttonText: 'Show Details',
      buttonEvent: `open-task-form/${a.data.step_id}/${a.data.task_id}`
    }
  });
};

const hydrateSignature = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  const meaning = longSignatureMeaning(a.data.meaning);
  return buildDefaultHistory(a, {
    title: 'Signature Added',
    body: [
      `${a.data.user.first_name} ${a.data.user.last_name} signed the form "${a.data.form_name}" in task "${task.title}" of step "${step.title}".`,
      `Signature Reason: ${meaning}.`
    ],
    button: {
      buttonText: 'Show Details',
      buttonEvent: `open-task-form/${a.data.step_id}/${a.data.task_id}`
    }
  });
};

const hydrateSignatureRemoved = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Signature Removed',
    body: [
      `${toFullName(a)} modified a signature-locked field in ${a.data.form_name}. The signature was automatically removed from the form.`
    ],
    isAlert: true,
    button: {
      buttonText: 'Show Details',
      buttonEvent: `open-task-form/${a.data.step_id}/${a.data.task_id}/${a.data.submission_id}`
    }
  });
};

const hydrateSubmissionSkipped = (a: ActivityHistoryItem): HydratedActivityHistory => {
  const dueAtSentence = toLocalSentence(a.data.due_at);
  return buildDefaultHistory(a, {
    title: 'Recurring Task Skipped',
    body: [
      `${toFullName(a)} skipped the task "${a.data.task_title}".`,
      dueAtSentence
    ],
    isAlert: true
  });
};

const hydrateSubmissionOverdue = (a: ActivityHistoryItem): HydratedActivityHistory => {
  const submissionDue = toLocalSentence(a.data.submission_was_due);
  const taskName = a.data.task_title;
  return buildDefaultHistory(a, {
    title: 'Task Submitted When Overdue',
    body: [
      `A submission for "${taskName}" was made by ${toFullName(a)}.`,
      `The submission was due on ${submissionDue}`
    ],
    isAlert: true
  });
};

const hydrateTaskAdded = (a: ActivityHistoryItem): HydratedActivityHistory => {
  return buildDefaultHistory(a, {
    title: 'Task Added',
    body: [
      `${toFullName(a)} added the task "${a.data.title}".`
    ],
    shortBody: [
      `${toFullName(a)} added this task.`
    ]
  });
};

const hydrateSubmissionCopied = (a: ActivityHistoryItem): HydratedActivityHistory => {
  const inProgressTaskStatusLabel = getTaskStatusLabel('in_progress');
  return buildDefaultHistory(a, {
    title: 'Form Copy Created',
    body: [
      `${toFullName(a)} copied the form "${a.data.form_name}".`,
      `Number of tasks set back to "${inProgressTaskStatusLabel}": ${a.data.updated_task_ids.length}.`
    ],
    shortBody: [
      `${toFullName(a)} copied this form.`
    ]
  });
};

const hydrateTaskAssignee = (a: ActivityHistoryItem): HydratedActivityHistory => {
  const assignee: Assignee = path(['data', 'prop_val'], a);
  let body: string, title: string;
  if (assignee.id) {
    title = 'Task Assignee Updated';
    body = `${toFullName(a)} assigned this task to ${assignee.first_name} ${assignee.last_name}.`;
  } else {
    title = 'Task Assignee Removed';
    body = `${toFullName(a)} removed the task assignment.`;
  }

  return buildDefaultHistory(a, {
    title,
    body: [body]
  });
}

const hydrateTaskDueDate = (a: ActivityHistoryItem): HydratedActivityHistory => {
  const dueDate: string = path(['data', 'prop_val'], a);
  let body: string, title: string;
  if (dueDate !== '') {
    title = 'Task Due Date Updated';
    body = `${toFullName(a)} set this task’s due date to ${toLocalDate(a.data.prop_val)}`;
  } else {
    title = 'Task Due Date Removed';
    body = `${toFullName(a)} removed the task due date.`;
  }

  return buildDefaultHistory(a, {
    title,
    body: [body]
  });
}

// deprecated event, though we still need to handle it
const hydrateTaskOverdue = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory => {
  const step = p.steps.find((s) => s.id === a.data.step_id);
  const task = step.tasks.find((t) => t.id === a.data.task_id);
  return buildDefaultHistory(a, {
    title: 'Task Overdue',
    body: [
      `The task "${task.title}" of step "${step.title}" was registered as overdue.`
    ],
    isAlert: true
  });
};

const hydrateTaskPriority = (a: ActivityHistoryItem): HydratedActivityHistory => {
  const priority: string = path(['data', 'prop_val'], a);
  let body: string, title: string;
  if (priority !== '') {
    title = 'Task Priority Updated';
    body = `${toFullName(a)} set this task’s priority to “${priority}”.`;
  } else {
    title = 'Task Priority Removed';
    body = `${toFullName(a)} removed the task priority.`;
  }

  return buildDefaultHistory(a, {
    title,
    body: [body]
  });
}

const hydrateTaskPropertyUpdated = (a: ActivityHistoryItem): HydratedActivityHistory => {
  switch (a.data.prop_key) {
  case 'assignee': return hydrateTaskAssignee(a);
  case 'due_date': return hydrateTaskDueDate(a);
  case 'priority': return hydrateTaskPriority(a);
  default: return buildDefaultHistory(a, { title: `${a.type} - ${a.data.prop_key}` });
  }
};

// tslint:enable:max-line-length

export const hydrate = (a: ActivityHistoryItem, p: Process): HydratedActivityHistory => {
  const map = {
    'Processes::Process::Closed': hydrateProcessClosed,
    'Processes::Process::CommentAdded': hydrateCommentAdded,
    'Processes::Process::DoubleBlindMismatch': hydrateDoubleBlind,
    'Processes::Process::NumberOutOfRange': hydrateNumberOutOfRange,
    'Processes::Process::ReOpened': hydrateProcessReopened,
    'Processes::Process::SignatureRemoved': hydrateSignatureRemoved,
    'Processes::Process::Signed': hydrateSignature,
    'Processes::Process::Started': hydrateProcessStarted,
    'Processes::Process::StepAdded': hydrateStepAdded,
    'Processes::Process::SubmissionCopied': hydrateSubmissionCopied,
    'Processes::Process::SubmissionSkipped': hydrateSubmissionSkipped,
    'Processes::Process::SubmissionOverdue': hydrateSubmissionOverdue,
    'Processes::Process::SummaryEmailed': hydrateProcessShared,
    'Processes::Process::SummarySubmissionUpdated': hydrateSummaryUpdated,
    'Processes::Process::TaskAdded': hydrateTaskAdded,
    'Processes::Process::TaskCompleted': hydrateTaskCompleted,
    'Processes::Process::TaskInvalidated': hydrateTaskInvalidated,
    'Processes::Process::TaskMarkedOverdue': hydrateTaskOverdue, // deprecated event
    'Processes::Process::TaskPropertyUpdated': hydrateTaskPropertyUpdated,
    'Processes::Process::TaskRuleApplied': hydrateTaskRuleApplied,
    'Processes::Process::SummaryTaskRuleApplied': hydrateSummaryTaskRuleApplied,
    'Processes::Process::TaskStarted': hydrateTaskStarted,
    'Processes::Process::TaskStopped': hydrateTaskStopped,
    'Processes::Process::TaskSubmissionUpdated': hydrateFormUpdated,
    'Processes::Process::TaskHalted': hydrateTaskHalted,
    'Processes::Process::TaskStatusOverride': hydrateTaskStatusOverride,
  };

  const fn = map[a.type];
  return typeof fn === 'function' ? fn(a, p) : buildDefaultHistory(a, { title: a.type });
};
